@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  scroll-behavior: smooth;
  font-size: 16px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

body {
  font-family: 'Raleway', sans-serif;
}

.Toastify__toast-body {
  font-family: 'Raleway', sans-serif;
  text-align: right;
  font-weight: bold;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

a,
button {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  text-decoration: none;
}

a {
  text-decoration: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card_img {
  height: 400px;
  width: 100%;
  position: relative;
}

.card_img .img_parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card_img .img_parent img, .card_img .img_parent video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.breadcrumb {
  padding: 100px 0;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb::after {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  content: "";
  width: 100%;
  height: 100%;
}

.breadcrumb h5 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  z-index: 3;
}

.removeLoading {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.openSideMenue {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  min-height: 100vh;
  z-index: 9;
  left: -100%;
}

.sideNav {
  background-color: #fff;
  width: 300px;
  height: 100vh;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  left: -100%;
}

.sideNav .cancel {
  padding: 15px 0;
  position: absolute;
  top: 0;
  right: 15px;
}

.sideNav .cancel img {
  width: 30px;
}

.sideNav .logo {
  text-align: center;
  padding: 30px 0 0 0;
}

.sideNav .logo img {
  width: 150px;
}

.sideNav .list li a {
  padding: 15px;
  border-bottom: 1px solid #eee;
  color: #0E142C;
  font-weight: bold;
  display: inline-block;
  width: 100%;
}

.loading_parent {
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

div.loading {
  width: 5rem;
  height: 5rem;
  background-color: #BF1E2E;
  border-radius: 5rem;
  position: relative;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation: spinning 1s linear infinite;
          animation: spinning 1s linear infinite;
}

div.loading::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 100%;
  -webkit-transform: translate(0.3rem);
          transform: translate(0.3rem);
}

@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

.inner_page_compnent {
  padding: 100px 0;
}

.contact_us_innerPage {
  margin-top: 0 !important;
}

/* top_navbar */
.top_navbar .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.top_navbar .wrapper .logo a {
  display: block;
  width: 100px;
}

.top_navbar .wrapper .social ul {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.top_navbar .wrapper .social ul li {
  margin-left: 10px;
}

.top_navbar .wrapper .social ul li a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #BF1E2E;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
}

/* custom_navbar */
.custom_navbar {
  background-color: #0E142C;
}

.custom_navbar .side_icon {
  display: none;
}

.custom_navbar ul.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
}

.custom_navbar .nav_link {
  color: #fff;
  font-size: .9rem;
  padding: 20px 15px;
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.custom_navbar .nav_link:hover {
  background-color: #fff;
  color: #0E142C;
}

.custom_navbar .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.custom_navbar .join_us .join_us_link {
  background-color: #BF1E2E;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 10px;
  color: #fff;
  border-color: #BF1E2E;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: .9rem;
}

.custom_navbar .join_us .join_us_link:hover {
  background-color: #fff;
  color: #BF1E2E;
}

.section_header {
  position: relative;
  padding-left: 15px;
}

.section_header::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #BF1E2E;
  left: 0;
  top: 0;
  border-radius: 2px;
}

.section_header h6 {
  color: #262161;
  font-size: 1.2rem;
  margin: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.section_header h5 {
  font-size: 2.5rem;
  color: #0E142C;
  margin: 0;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* HEADER */
.header .card_img {
  height: calc(100vh - 165px);
}

/* about_us_section */
.about_us_section {
  padding: 50px 0;
}

.about_us_section .about_img {
  text-align: center;
}

.about_us_section .about_img img {
  width: 70%;
}

.about_us_section .about_content {
  margin-top: 40px;
}

.about_us_section .about_content h5 {
  font-size: 2rem;
  letter-spacing: 3px;
}

.about_us_section .about_content .readmore {
  background-color: #BF1E2E;
  color: #fff;
  padding: 10px 30px;
  margin-top: 5px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  border: 1px solid #BF1E2E;
}

.about_us_section .about_content .readmore:hover {
  background-color: #fff;
  color: #BF1E2E;
}

/* services */
.services {
  padding: 50px 0;
}

.services .section_header {
  margin-bottom: 50px;
}

/* services_card */
.services_card {
  margin-bottom: 40px;
  border: 1px solid #eee;
  padding: 20px 15px;
  border-radius: 5px;
  min-height: 310px;
}

.services_card .service_img {
  text-align: center;
  margin-bottom: 10px;
}

.services_card .service_img img {
  width: 30%;
  margin: auto;
}

.services_card .card_body {
  text-align: center;
}

.services_card .card_body .card_title {
  font-size: 1.5rem;
}

.services_card .card_body .card_text {
  color: #000;
  line-height: 23px;
}

/* organization_card */
.our_company .section_header {
  margin-bottom: 50px;
}

.organization_card {
  text-align: center;
  margin-bottom: 30px;
}

.organization_card .card_logo {
  margin-bottom: 10px;
}

.organization_card .card_logo img {
  width: 120px;
}

.organization_card .card_title {
  font-size: 1.5rem;
  color: #BF1E2E;
}

.organization_card .card_text {
  color: #000;
  line-height: 23px;
}

.organization_card .organ_link {
  background-color: #0E142C;
  color: #fff;
  padding: 7px 30px;
  margin-top: 10px;
  border: 1px solid #0E142C;
  display: inline-block;
  border-radius: 5px;
}

.organization_card .organ_link i {
  font-size: .9rem;
  margin-left: 5px;
}

.organization_card .organ_link:hover {
  background-color: #fff;
  color: #0E142C;
}

/* about_us */
.about_us {
  padding: 30px 0;
}

.about_us .about_img_parnent {
  height: 300px;
  position: relative;
}

.about_us .about_img_parnent .img_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about_us .about_img_parnent .img_wrapper img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.about_us .about_content p {
  font-size: 1.3rem;
}

.about_us .about_content h5 {
  font-size: 1.3rem;
  font-weight: bold;
}

.about_us .about_content ul {
  margin-top: 30px;
}

.about_us .about_content ul .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about_us .about_content ul li {
  border: 1px solid #eee;
  margin-bottom: 20px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.about_us .about_content ul li img {
  width: 50px;
  margin-bottom: 10px;
  display: inline-block;
}

.about_us .about_content ul li span {
  font-size: 1.2rem;
}

.about_us .about_content ul li:hover {
  background-color: #0E142C;
}

.about_us .about_content ul li:hover span {
  color: #fff;
}

/* contact_us */
.contact_us {
  padding: 50px 0;
  margin: 100px 0;
  margin-bottom: 0;
  background-size: cover;
  background-position: center;
  position: relative;
}

.contact_us::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
}

.contact_us .contact_header {
  text-align: center;
  position: relative;
  z-index: 3;
}

.contact_us .contact_header h5 {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.contact_us .contact_header p {
  letter-spacing: 3px;
  color: #fff;
  text-transform: capitalize;
  margin: 0;
  font-size: 1.5rem;
}

.contact_us .contact_form {
  position: relative;
  z-index: 3;
  width: 70%;
  margin: auto;
  margin-top: 50px;
}

.contact_us .contact_form .form-control {
  height: 50px;
  border-radius: 0;
  background-color: #fff;
  border-color: #fff;
}

.contact_us .contact_form .form-control::-webkit-input-placeholder {
  text-transform: capitalize;
}

.contact_us .contact_form .form-control:-ms-input-placeholder {
  text-transform: capitalize;
}

.contact_us .contact_form .form-control::-ms-input-placeholder {
  text-transform: capitalize;
}

.contact_us .contact_form .form-control::placeholder {
  text-transform: capitalize;
}

.contact_us .contact_form textarea {
  height: 150px !important;
  resize: none;
}

.contact_us .btn_submit {
  text-align: center;
}

.contact_us .btn_submit .btn {
  background-color: #BF1E2E;
  color: #fff;
  margin-top: 20px;
  border-radius: 0;
  padding: 10px 50px;
}

.contact_us .error_msg {
  color: #fff;
  font-size: .9rem;
}

/* sitefooter */
.sitefooter {
  padding: 20px 0;
}

.sitefooter .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sitefooter .wrapper h6 {
  margin-bottom: 0;
}

.sitefooter .wrapper ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
}

.sitefooter .wrapper ul li {
  margin-right: 30px;
}

.sitefooter .wrapper ul li a {
  color: #0E142C;
}

.sitefooter .wrapper ul li a i {
  margin-right: 5px;
  color: #BF1E2E;
}

.sitefooter .wrapper ul li a span {
  font-weight: 600;
}

.check_certificate {
  background-color: #0e142c13;
  padding-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}

.check_certificate .login_wave {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.check_certificate .login_wave img {
  width: 100%;
}

.check_certificate .check_form {
  background-color: #fff;
  padding: 30px 50px;
  width: 50%;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
          box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
  margin: auto;
  text-align: center;
  position: relative;
  z-index: 8;
  margin-bottom: 50px;
}

.check_certificate .check_form img {
  width: 200px;
  display: inline-block;
}

.check_certificate .check_form h5 {
  margin-bottom: 20px;
}

.check_certificate .check_form .form-group {
  margin-bottom: 0;
}

.check_certificate .check_form .form-group .form-control {
  height: 50px;
  border: 1px solid #eee;
}

.check_certificate .check_form .form-group .form-control::-webkit-input-placeholder {
  font-size: .9rem;
}

.check_certificate .check_form .form-group .form-control:-ms-input-placeholder {
  font-size: .9rem;
}

.check_certificate .check_form .form-group .form-control::-ms-input-placeholder {
  font-size: .9rem;
}

.check_certificate .check_form .form-group .form-control::placeholder {
  font-size: .9rem;
}

.check_certificate .check_form .form-group .form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #01358fab;
}

.check_certificate .check_form .btn {
  background-color: #0E142C;
  color: #fff;
  padding: 8px 50px;
  margin-top: 20px;
  border: 1px solid #0E142C;
}

.check_certificate .check_form .btn:hover {
  background-color: #fff;
  color: #0E142C;
}

.check_certificate .stars {
  position: absolute;
  top: 0;
}

.check_certificate .stars i {
  position: absolute;
  color: #fff;
}

.certificate_detials {
  height: auto;
  background-color: #fff;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}

.certificate_detials .circle {
  position: absolute;
  top: 50%;
  left: -10%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 1;
}

.certificate_detials .certificate_card {
  background-color: #fff;
  width: 80%;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 9;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.certificate_detials .certificate_card li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.certificate_detials .certificate_card li .title {
  font-weight: bold;
  font-size: 1.2rem;
}

.certificate_detials .certificate_card li .value {
  font-size: 1.1rem;
}

.certificate_detials .btn_close {
  text-align: center;
  margin-top: 15px;
  position: relative;
  z-index: 4;
}

.certificate_detials .btn_close .btn {
  padding: 10px 50px;
  background-color: #BF1E2E;
  border-radius: 0;
  border: 1px solid #BF1E2E;
}

.certificate_detials .btn_close .btn:hover {
  background-color: #fff;
  color: #BF1E2E;
}

/* not_found */
.not_found {
  text-align: center;
  padding-bottom: 50px;
}

.not_found img {
  width: 45%;
  margin: auto;
}

.not_found h5 {
  font-size: 2rem;
  text-transform: uppercase;
  color: #0E142C;
  margin: 0;
}

.not_found a {
  background-color: #BF1E2E;
  color: #fff;
  padding: 10px 50px;
  margin-top: 20px;
  display: inline-block;
  border: 1px solid #BF1E2E;
}

.not_found a:hover {
  background-color: #fff;
  color: #BF1E2E;
}

@media (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .check_certificate .check_form {
    width: 100%;
  }
}

@media (max-width: 524px) {
  .certificate_card {
    width: 100%;
  }
  .certificate_card li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .certificate_card li .title {
    margin-bottom: 5px;
  }
  .custom_navbar .side_icon {
    display: block;
    padding: 10px 15px;
  }
  .custom_navbar .side_icon img {
    width: 35px;
    color: #fff;
  }
  .custom_navbar ul.list {
    display: none;
  }
  .organization_card .card_logo img {
    width: 100px;
  }
  .sitefooter .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .sitefooter .wrapper ul li {
    margin-right: 0;
    margin-top: 15px;
  }
}
